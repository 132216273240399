$light: rgb(183, 183, 183);
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin dev {
  border: 1px solid red;
}
