.dash-container {
  height: 100vh;
}

.light {
  color: rgb(183, 183, 183);
  font-size: small;
}

.val-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
}

.val {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tick text {
  font-size: 0.8rem;
}

.account {
  width: 93%;
  margin: auto;
  text-align: left;
  overflow: hidden;
}

.assets-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.assets-header {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 93%;
}

.Asset {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid grey;
}

.chart-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  min-width: 15em;
  height: 50vh;
  border: solid 1px grey;
}

.filter-icon {
  cursor: pointer;
  margin-left: 90%;
}

.filter-container {
  margin-left: 80%;
}/*# sourceMappingURL=Dashboard.css.map */