@import "./variables";


.container{
    a {
        color: rgb(59, 173, 89)
    }
    display: flex;
    align-items: baseline;
    flex-direction: column;
    width: 50%;
    margin: auto;
    margin-top: 5em;
}

.section{
    display: flex;
    align-items: baseline;
    flex-direction: column;
    margin-top: 2.5em;
    h3{
        margin-bottom: .5em;
    }
    p{
        text-align: justify;
    }
}