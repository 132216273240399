.trade-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
}

.trade {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid grey;
}

.icon-memo {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 10%;
  white-space: nowrap;
}

.msg-val {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.msg-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-stamp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 10%;
}

a {
  cursor: pointer;
}/*# sourceMappingURL=Trade.css.map */