.value-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  min-width: 15em;
  height: 50vh;
  border: 1px grey solid;
  position: relative;
}
.value-container h1 {
  position: absolute;
  width: 30%;
  transform: translateY(-50%);
}

.asset-chart {
  display: flex;
  width: 93%;
}

.chart-control p {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.asset-stats {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  width: 30%;
}/*# sourceMappingURL=Value.css.map */