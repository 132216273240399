a {
  color: black;
  text-decoration: none;
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin-bottom: 5vh;
  border-bottom: 1px solid grey;
}
.header-container input {
  padding: 0.5rem;
  width: 20rem;
  border: 1px solid grey;
  border-right: none;
}
.header-container .small_search form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-container .small-search-btn {
  padding: 0.45rem;
  background-color: white;
  border: 1px solid grey;
  border-left: none;
  height: 100%;
}/*# sourceMappingURL=Header.css.map */