@import "./variables";

// the circle chart is terrible on mobile just not responive enough

.value-container {
  @include flexCenter;
  width: 45%;
  min-width: 15em;
  height: 50vh;
  border: 1px grey solid;
  position: relative;
  h1 {
    position: absolute;
    width: 30%;
    transform: translateY(-50%);
  }
}

.asset-chart {
  // @include dev;
  display: flex;
  width: 93%;
}

.chart-control {
  p {
    cursor: pointer;
    user-select: none;
  }
}

.asset-stats {
  // @include dev;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  width: 30%;
}
